import address from "./address/index";
import orders from "./orders/index";
import products from "./products/index";
import loading from "./loading";
import advisor from "./advisor/index"
import cart from "./cart/index"
import banners from "./banners/index"
import survey from "./survey/index"

export default {
    address,
    orders,
    products,
    loading,
    advisor,
    cart,
    banners,
    survey
}