<template>
    <div class="orders-history-order">
        <div class="d-flex align-items-center">
            <div class="flex-grow-1 pr-3">
                {{t('title')}}
            </div>
            <div class="flex-grow-0 pr-3">
                <div class="small">
                    {{d(new Date(value.createdOn), 'datetime')}}
                </div>
            </div>
            <div class="flex-grow-0">
                <button type="button" class="btn-switcher" @click="display.details=!display.details">
                    <span :class="{'icon-plus':!display.details,'icon-minus':display.details}"></span>
                </button>
            </div>
        </div>

        <div v-if="display.details" class="py-3">
            <orders-history-order-product v-for="product in value.products"
                                          :key="product._id"
                                          :expand="expand"
                                          :product="product"
                                          @refresh="$emit('refresh')"/>
        </div>

    </div>
</template>

<script>

    import OrdersHistoryOrderProduct from "@/components/orders/OrdersHistoryOrderProduct";

    export default {
        name: "OrdersHistoryOrder",
        emits: ["refresh"],
        components: {
            OrdersHistoryOrderProduct
        },
        props: {
            value: Object,
            expand: Boolean
        },
        data() {

            return {
                t_path: "components.orders.history.order",

                display: {
                    details: false
                }
            }
        }
    }
</script>