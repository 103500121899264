<template>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col-12">
                <loading></loading>
            </div>
        </div>
    </div>
    <page-message v-else>
        <template #header>{{header}}</template>
        <template #message>{{message}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import {v4 as uuidv4} from "uuid";

    import mixinView from "@/mixins/view";
    import PageMessage from "@/components/page/PageMessage";

    const SERVICE_MAPPING = {
        tfalogin: "$platform",
        tfaaddress: "$order",
        delivery: "$delivery"
    };

    export default {
        name: "ViewsTunnelService",
        components: {
            PageMessage
        },
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.tunnel.service",

                loading: false,

                path: {
                    header: null,
                    message: null
                }
            };
        },
        computed: {
            header() {

                if (this.path.header) {

                    return this.$t(this.path.header);
                }

                return null;
            },
            message() {

                if (this.path.message) {

                    return this.$t(this.path.message);
                }

                return null;

            }
        },
        methods: {
            handle(code, results) {

                if (this.$store.getters["user/isAuthenticated"]) {

                    // Refresh user data if user is authenticated
                    this.$store.dispatch("user/fetch");
                }

                if (-1 !== new String(code).indexOf("_delivery")) {

                    const status = this.$route.query.status.toLowerCase();

                    if (-1 !== ["200_delivery_cta", "200_delivery"].indexOf(code)) {

                        const itemsReference = uuidv4();

                        this.$storage.set(itemsReference, results.items);

                        return this.$router.push({
                            name: "survey.delivery",
                            query: {
                                order_id: results.order_id,
                                reference_ids: this.$route.query.reference_ids,
                                status,
                                itemsReference
                            }
                        });
                    }

                    if ("yes" === status && "204_delivery" == code) {

                        const itemsReference = uuidv4();

                        this.$storage.set(itemsReference, results.items);

                        return this.$router.push({
                            name: "survey.customer",
                            query: {
                                order_id: results.order_id,
                                reference_ids: this.$route.query.reference_ids,
                                itemsReference
                            }
                        });
                    }
                }

                if ("204_shipment_received" == code) {

                    return this.$router.push({
                        name: "survey.deliveryfaq",
                        query: {
                            items: JSON.stringify(results.items)
                        }
                    });
                }

                if ("200_tfaaddress" == code) {

                    this.$broadcast.post(this.$broadcast.messages.refresh_addresses);
                }

                const path = `${this.t_path}.responses.${code}`;

                this.view_setTitle(this.$t(`${path}.title`));

                this.path.header = `${path}.header`;
                this.path.message = `${path}.message`;
            },
            fetch() {

                this.loading = true;

                const service = SERVICE_MAPPING[this.$route.params.service.split("/")[0]];

                if ("undefined" === typeof service) {

                    return this.$router.push({name: "errors.404"})
                }

                return this[service].get('/', {timeout: 10000})
                    .then(() => {

                        const path = `/${this.$route.params.service.split("/").slice(1).join("/")}`;

                        this[service].get(path, {params: this.$route.query})
                            .then(response => this.handle(response.data.meta.code, response.data.results))
                            .catch(error => {

                                if (error.response && 500 > error.response.status && error.response.data && error.response.data.meta) {

                                    return this.handle(error.response.data.meta.code, error.response.data.results);
                                }

                                return this.$router.push({name: "errors.500"});
                            })
                            .finally(() => this.loading = false);
                    })
                    .catch(error => {

                        if (error.isAxiosError && "undefined" === typeof error.response) {

                            this.path.header = "networkIssuesHeader";
                            this.path.message = "networkIssues";
                        }

                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.fetch();
        }
    }
</script>