<template>
    <page-message v-if="saved">
        <template #header>{{t('result.header')}}</template>
        <template #html>
            {{t('result.message')}}
        </template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <div v-else class="container survey-delivery">
        <div class="row">
            <div class="col-12 offset-lg-2 col-lg-8">
                <div v-if="message" class="text-center pb-4 font-weight-bold">
                    {{t(message)}}
                </div>
                <form @submit.prevent="submit">

                    <div v-for="item in items" class="product" :key="item._id">
                        <div class="row">
                            <div class="col-4 col-lg-3">
                                <form-checkbox :selected-value="item.reference_id"
                                               :disabled="processing"
                                               :label="t('model.checked.label')"
                                               v-model="model.checked"/>
                            </div>
                            <div class="col-8 col-lg-9">
                                <survey-delivery-product :value="item"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group text-center pt-2">
                        <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                            <spinner v-if="processing"/>
                            {{processing ? t('button.submit.processing') : t('button.submit.cta')}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

    import mixinView from "@/mixins/view";
    import {retryRequest} from "@/helpers/request";

    import Panel from "@/components/Panel";
    import FormCheckbox from "@/components/form/FormCheckbox";
    import PageMessage from "@/components/page/PageMessage";
    import SurveyDeliveryProduct from "@/components/survey/SurveyDeliveryProduct";

    const STATUS_YES = "yes";
    const STATUS_NO = "no";

    export default {
        name: "ViewsSurveyDelivery",
        components: {
            SurveyDeliveryProduct,
            PageMessage,
            FormCheckbox,
            Panel
        },
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.survey.delivery",
                saved: false,
                message: null,

                items: [],

                model: {
                    checked: []
                }
            }
        },
        methods: {
            submit() {

                this.processing = true;

                const request = {
                    order_id: this.$route.query.order_id,
                    delivery_received: this.model.checked,
                    delivery_not_received: this.items.filter(item => -1 === this.model.checked.indexOf(item.reference_id)).map(i => i.reference_id)
                };

                return retryRequest(() => this.$delivery.post("/confirm", request))
                    .then(response => {

                        if (response.data.meta.code) {

                            if (-1 !== ["204_shipment_received"].indexOf(response.data.meta.code)) {

                                return this.$router.push({
                                    name: "survey.deliveryfaq",
                                    query: {
                                        items: JSON.stringify(this.items)
                                    }
                                });
                            }

                            if (-1 !== ["204_delivery"].indexOf(response.data.meta.code)) {

                                return this.$router.push({
                                    name: "survey.customer",
                                    query: {
                                        order_id: this.$route.query.order_id,
                                        reference_ids: this.$route.query.reference_ids,
                                        itemsReference: this.$route.query.itemsReference
                                    }
                                });
                            }
                        }

                        this.saved = true;
                    })
                    .catch(error => {

                        if (!error.response) {

                            return this.$toast.error(this.t("errors.requestError"), {
                                timeout: 5000
                            });
                        }

                        this.base_error(error);
                    })
                    .finally(() => this.processing = false);
            },
            fetch() {

                this.items = this.$storage.get(this.$route.query.itemsReference, []);

                if (STATUS_YES == this.$route.query.status) {

                    this.items.forEach(item => this.model.checked.push(item.reference_id));
                    this.message = "status.yes";
                }

                if (STATUS_NO == this.$route.query.status) {

                    this.message = "status.no";
                }
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>