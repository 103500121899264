<template>
    <div v-if="loading" class="container">
        <div class="row">
            <div class="col-12">
                <loading/>
            </div>
        </div>
    </div>
    <page-message v-else-if="error">
        <template #header>{{t('error')}}</template>
        <template #message>{{errorTranslated}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <form v-else @submit.prevent="base_submit('model', 'submit', null)">
        <div class="container approvals-delegate">
            <div class="row">
                <div class="col-12 col-md-3">
                    <div class="panel">
                        <div>
                            <router-link :to="{name: 'approvals'}" active-class="font-weight-bold">
                                {{$t('views.approval.menu.approvals')}}
                            </router-link>
                        </div>
                        <div class="pt-1">
                            <router-link :to="{name: 'approvals.delegate'}" active-class="font-weight-bold">
                                {{$t('views.approval.menu.delegates')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-9">
                    <table v-if="0 < delegates.length" class="table small items">
                        <thead>
                        <tr>
                            <th class="email">{{t('delegates.email')}}</th>
                            <th class="date">{{t('delegates.startDate')}}</th>
                            <th class="date" colspan="3">{{t('delegates.endDate')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(delegate, delegateIndex) in delegates" :key="delegate.id">
                            <td class="email">{{delegate.delegate_email}}</td>
                            <td class="date">{{delegate.start_on ? d(delegate.start_on, 'date') : null}}</td>
                            <td class="date">{{delegate.end_on ? d(delegate.end_on, 'date') : null}}</td>
                            <td>
                                <button type="button" class="btn btn-sm p-0"
                                        :disabled="processing[delegateIndex]"
                                        @click="edit(delegateIndex)">
                                    <span class="icon-pencil"></span>
                                </button>
                            </td>
                            <td class="control">
                                <button type="button" class="btn btn-sm p-0"
                                        :disabled="processing[delegateIndex]"
                                        @click="remove(delegateIndex)">
                                    <span v-if="processing[delegateIndex]" class="icon-spinner animate-spin"></span>
                                    <span v-else class="icon-cancel"></span>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="text-center">
                        <button type="button" class="btn btn-primary" @click="add">
                            {{t('buttons.add.text')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

    import useVuelidate from "@vuelidate/core";

    import mixinView from "@/mixins/view";
    import mixinValidator from "@/helpers/validator";

    import PageMessage from "@/components/page/PageMessage";
    import Spinner from "@/components/Spinner";
    import FormTextbox from "@/components/form/FormTextbox";
    import ModalApprovalDelegateEditor from "@/modal/ModalApprovalDelegateEditor";
    import {offsetUTCTimestampToLocal} from "@/helpers/date";

    export default {
        name: "ViewsDelegate",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [mixinView, mixinValidator],
        components: {
            Spinner,
            FormTextbox,
            PageMessage
        },
        data() {

            return {
                t_path: "views.approval.delegates",
                processing: {},
                error: false,
                loading: true,
                delegates: []
            }
        },
        computed: {
            errorTranslated() {

                return this.error ? this.$t(this.error) : null;
            }
        },
        methods: {
            fetch() {

                this.loading = true;

                this.$order
                    .get(`/users/${this.$store.getters["user/id"]}/delegates`)
                    .then(response => this.delegates = response.data.results.data.map(item => ({
                        id: item._id,
                        delegate_email: item.delegate_email,
                        start_on: offsetUTCTimestampToLocal(item.start_on),
                        end_on: offsetUTCTimestampToLocal(item.end_on)
                    })))
                    .catch(error => this.error = this.base_error_messages(error, true, false))
                    .finally(() => this.loading = false);
            },
            add() {

                this.$modal
                    .show({component: ModalApprovalDelegateEditor})
                    .then(() => this.fetch());
            },
            edit(index) {

                this.$modal
                    .show({
                        component: ModalApprovalDelegateEditor,
                        props: {
                            delegate: this.delegates[index]
                        }
                    })
                    .then(() => this.fetch());
            },
            remove(index) {

                return this.$modal
                    .confirm(this.t("confirm.delete"))
                    .then(() => {

                        this.processing[index] = true;

                        this.$order
                            .delete(`/users/${this.$store.getters["user/id"]}/delegates/${this.delegates[index].id}`)
                            .then(() => this.$toast.success(this.t("delegateDelete")) && this.fetch())
                            .catch(error => this.base_error(error))
                            .finally(() => this.processing[index] = false)
                    });
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>