export default {

    title: "Loading...",

    responses: {
        "200_tfalogin": {
            title: "Login Verification",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "Login has been verified."
        },
        "404_tfalogin": {
            title: "Login Verification",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Valid token not found. Your token might have expired."
        },
        "200_tfaaddress": {
            title: "Address Verification",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Thanks for verifying your address. You can now choose this as a shipping address when you place an order."
        },
        "404_tfaaddress": {
            title: "Address Verification",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Valid token not found. Your token might have expired."
        },
        "201_delivery": {
            title: "Thank you",
            header: "Thank you",
            message: "Thank you for your response."
        },
        "201_shipment_received": {
            title: "Thank you",
            header: "Thank you",
            message: "Thank you for your response."
        },
        "400": {
            title: "Bad Request. An error occurred.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Bad Request. An error occurred."
        }
    }
}