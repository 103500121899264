<template>
    <page-message v-if="saved">
        <template #header>{{t('result.header')}}</template>
        <template #html>
            {{t('result.message')}}
        </template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <div v-else class="container">
        <div class="row">
            <div class="col-12 offset-lg-2 col-lg-8">
                <h3 class="text-center pb-3">{{t('header')}}</h3>
                <p>{{t('message')}}</p>
                <p>{{t('cta')}}</p>
                <ul>
                    <li>
                        {{t('items.1.message')}}
                        <br/>
                        <a :href="trackingUrl" target="_blank" rel="noopener">{{trackingUrl}}</a>
                    </li>
                    <li>
                        {{t('items.2.message')}}
                        <ul>
                            <li>{{t('items.2.items.1')}}</li>
                            <li>{{t('items.2.items.2')}}</li>
                            <li>{{t('items.2.items.3')}}</li>
                        </ul>
                    </li>
                    <li>
                        {{t('items.3.message')}}
                    </li>
                    <li>
                        {{t('items.4.message')}}
                    </li>
                </ul>
                <p>{{t('question')}}</p>
            </div>
            <div class="col-6 text-right">
                <button type="button" class="btn btn-primary" :disabled="disabled" @click="submit('No')">
                    <spinner v-if="processing.no"></spinner>
                    {{processing.no ? t('buttons.saving') : t('buttons.no')}}
                </button>
            </div>
            <div class="col-6">
                <button type="button" class="btn btn-primary" :disabled="disabled" @click="submit('Yes')">
                    <spinner v-if="processing.yes"></spinner>
                    {{processing.yes ? t('buttons.saving') : t('buttons.yes')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixinView from "@/mixins/view";
    import {retryRequest} from "@/helpers/request";

    import Panel from "@/components/Panel";
    import FormCheckbox from "@/components/form/FormCheckbox";
    import Spinner from "@/components/Spinner";
    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsSurveyDelivery",
        components: {
            PageMessage,
            Spinner,
            FormCheckbox,
            Panel
        },
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.survey.deliveryfaq",
                disabled: false,
                saved: false,

                trackingUrl: null,
                processing: {
                    yes: false,
                    no: false
                },

                items: []
            }
        },
        methods: {
            submit(response) {

                this.disabled = true;
                this.processing[response] = true;

                const request = {
                    response,
                    item: this.item,
                };

                return retryRequest(() => this.$delivery.post("/shipment-not-received-survey", request))
                    .then(() => this.saved = true)
                    .catch(error => this.base_error(error))
                    .finally(() => {
                        this.disabled = false;
                        this.processing[response] = false;
                    });
            },
            fetch() {

                this.item = JSON.parse(this.$route.query.items)[0];
                this.trackingUrl = this.item.tracking_url;
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>