import {english} from "flatpickr/dist/l10n/default";
import {Portuguese} from "flatpickr/dist/l10n/pt";
import {French} from "flatpickr/dist/l10n/fr";
import {Spanish} from "flatpickr/dist/l10n/es";
import {Hungarian} from "flatpickr/dist/l10n/hu";
import {Italian} from "flatpickr/dist/l10n/it";
import {Czech} from "flatpickr/dist/l10n/cs";
import {German} from "flatpickr/dist/l10n/de";
import {Dutch} from "flatpickr/dist/l10n/nl";
import {Polish} from "flatpickr/dist/l10n/pl";
import {Mandarin} from "flatpickr/dist/l10n/zh";

const config = {
    default: {
        displayDateFormat: "m/d/Y",
        locale: english
    },
    "en-US": {
        displayDateFormat: "m/d/Y",
        locale: english
    },
    pt: {
        displayDateFormat: "d/m/Y",
        locale: Portuguese
    },
    fr: {
        displayDateFormat: "d/m/Y",
        locale: French
    },
    es: {
        displayDateFormat: "d/m/Y",
        locale: Spanish
    },
    hu: {
        displayDateFormat: "Y. m. d.",
        locale: Hungarian
    },
    it: {
        displayDateFormat: "d/m/Y",
        locale: Italian
    },
    cs: {
        displayDateFormat: "d. m. Y",
        locale: Czech
    },
    de: {
        displayDateFormat: "d.m.Y",
        locale: German
    },
    nl: {
        displayDateFormat: "d-m-Y",
        locale: Dutch
    },
    pl: {
        displayDateFormat: "d.m.Y",
        locale: Polish
    },
    "zh-CN": {
        displayDateFormat: "Y/m/d",
        locale: Mandarin
    }
}

export const getPickerConfig = locale => [
    locale,
    locale.split("-")[0],
    "default"
].reduce((result, locale) => result ? result : config[locale], null);