import countries from "@/store/db/countries/items"

const config = {
    date: {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    },
    time: {
        hour: "numeric",
        minute: "numeric"
    },
    datetime: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
    }
}

const languages = ["en-US", "pt-BR", "pt", "fr", "es", "hu", "it", "cs", "de", "nl", "pl", "zh-CN"];

export default languages.reduce((result, language) => {

    if (2 < language.length) {

        return  {
            ...result,
            [language]: config
        }
    }

    result[language] = config;

    countries.forEach(country => result[`${language}-${country.alpha2}`] = config);

    return result;
}, {});