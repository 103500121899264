export default {
    title: "Delivery Confirmation",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Thank you",
        message: "Thank you for your response."
    },
    status: {
        yes: "Please verify the checked products below to confirm delivery and uncheck the ones you have not received. Then hit Submit to confirm.",
        no: "Please verify that you did not receive any of the products below. If you did receive any of these products, please check the box next to received product(s) and hit Submit to confirm."
    },
    model: {
        checked: {
            label: "Received"
        }
    },
    button: {
        submit: {
            cta: "Submit",
            processing: "Submitting"
        }
    }
}