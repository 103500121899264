import {getStorage} from "@/api/storage";
import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";

const STORAGE_KEY = "language";

const ENABLED_LANGUAGES = process.env.VUE_APP_ENABLED_LANGUAGES.split(",");
const LANGUAGES = [
    {
        name: "English",
        value: "en-US"
    },
    {
        name: "Português (Brasil)",
        value: "pt-BR"
    },
    {
        name: "Português",
        value: "pt"
    },
    {
        name: "Française",
        value: "fr"
    },
    {
        name: "Española",
        value: "es"
    },
    {
        name: "Magyar",
        value: "hu"
    },
    {
        name: "Italiano",
        value: "it"
    },
    {
        name: "Čeština",
        value: "cs"
    },
    {
        name: "Deutsch",
        value: "de"
    },
    {
        name: "Nederlands",
        value: "nl"
    },
    {
        name: "Polski",
        value: "pl"
    },
    {
        name: "普通话",
        value: "zh-CN"
    }
];

const DEFAULT_LANGUAGE = LANGUAGES.find(language => "en-US" === language.value);

const processLanguage = language => {

    try {
        language = language || getStorage().get(STORAGE_KEY, DEFAULT_LANGUAGE);
    } catch (e) {
        language = DEFAULT_LANGUAGE;
    }

    return language ?? DEFAULT_LANGUAGE;
}

export default {
    namespaced: true,
    state: {
        language: LANGUAGES.find(language => "en-US" === language.value),
        languages: LANGUAGES.filter(lang => -1 !== ENABLED_LANGUAGES.indexOf(lang.value))
    },
    getters: {
        language: state => state.language,
        languages: state => state.languages
    },
    mutations: {
        setLanguage: (state, language) => {

            const result = state.languages.find(l => {

                if ("string" === typeof language) {

                    return l.value == language;
                }

                return l.value == language.value;
            });

            state.language = result ? result : DEFAULT_LANGUAGE;
        },
        updateStorage: (state) => getStorage().set(STORAGE_KEY, state.language)
    },
    actions: {
        setLanguage(context, language) {

            context.commit("setLanguage", language);
            context.commit("updateStorage");
        },
        fetch(context, language) {

            return new Promise(resolve => {

                if (!this.getters["user/isAuthenticated"]) {

                    context.commit("setLanguage", processLanguage(language));

                    return resolve(context.state.language);
                }

                // Get language from user profile
                if (context.rootGetters["user/profile/loaded"]) {

                    context.commit("setLanguage", processLanguage(context.rootGetters["user/profile/language"]));

                    return resolve(context.state.language);
                }

                return retryRequest(() => platform.get("/api/v2/widget/style-profile"))
                    .then(response => {

                        if (response.data.results.language) {

                            language = context.state.languages
                                .find(language => language.value == response.data.results.language);
                        }

                        context.commit("setLanguage", processLanguage(language));

                        return resolve(context.state.language);
                    }).catch(() => {

                        context.commit("setLanguage", processLanguage(language));
                        context.commit("updateStorage");

                        return resolve(context.state.language);
                    });
            });
        }
    }
}